import React from "react";
import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

const EmbedDocument = ({ content }) => {
    return (
        <Wrapper gap="gap.medium" column>
            <Iframe src={content} title="Justificatif" />
        </Wrapper>
    );
};

export default EmbedDocument;

const Iframe = styled.embed`
    /* height: 637px; */
    height: 100%;
    width: 100%;
    /* width: 447px; */
`;

const Wrapper = styled(Flex)`
    /* border: 1px solid red; */
    min-height: 570px;
    width: 100%;
    /* max-width: 479px; */
    /* width: 479px;
    /* width: 100%; */
    padding: 16px;
    /* background: yellow; */
    /* background: var(--light-theme-rest-background-default-background, #fff); */
`;
